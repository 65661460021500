<template>
  <div class="cpumem-wrapper">

    <div class="cpumem-container">
      <div class="bar-section">
        <div class="bar-label">CPU</div>
        <div class="bar-wrapper">
          <div class="bar avg" :style="{ height: cpuAvgPct + '%' }" />
          <div class="bar peak" :style="{ height: cpuPeakPct + '%' }" />
        </div>
        <div class="bar-values">
          <div>{{ props.totalCpu.toLocaleString() }}</div>
        </div>
      </div>

      <div class="bar-section">
        <div class="bar-label">Mem</div>
        <div class="bar-wrapper">
          <div class="bar avg" :style="{ height: memAvgPct + '%' }" />
          <div class="bar peak" :style="{ height: memPeakPct + '%' }" />
        </div>
        <div class="bar-values">
           <div>{{ formatSize(props.totalMem) }}</div>
        </div>
      </div>

      <div class="bar-section">
        <div class="bar-label">Disk</div>
        <div class="bar-wrapper">
          <div class="bar avg" :style="{ height: diskUsage + '%' }" />
        </div>
        <div class="bar-values">
          <div>{{ formatSize(props.totalDisk) }}</div>
        </div>
      </div>

    </div>

      <div class="legend">
      <div class="legend-item" style="margin-left: 2px;">
        <div class="legend-color avg-bar"></div>
        <div class="legend-label" style="width: 51px;">Average</div>
      </div>
      <div class="legend-item" style="margin-left: 8px;">
        <div class="legend-color peak-bar"></div>
        <div class="legend-label" style="width: 31px;">Peak</div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  cpuAvgTotal: Number,
  cpuPeakTotal: Number,
  memAvgTotal: Number,
  memPeakTotal: Number,
  totalCpu: Number,
  totalMem: Number,
  totalDisk: Number,
  usedDisk: Number,
})

const diskUsage = computed(() =>
  props.totalDisk ? ((props.usedDisk / props.totalDisk) * 100).toFixed(1) : 0
)

const cpuAvgPct = computed(() =>
  props.totalCpu ? ((props.cpuAvgTotal / props.totalCpu) * 100).toFixed(1) : 0
)
const cpuPeakPct = computed(() =>
  props.totalCpu ? ((props.cpuPeakTotal / props.totalCpu) * 100).toFixed(1) : 0
)
const memAvgPct = computed(() =>
  props.totalMem ? ((props.memAvgTotal / props.totalMem) * 100).toFixed(1) : 0
)
const memPeakPct = computed(() =>
  props.totalMem ? ((props.memPeakTotal / props.totalMem) * 100).toFixed(1) : 0
)

function formatSize(value) {
  if (value > 10240) {
    return Math.round(value / 1024).toLocaleString() + ' TiB'
  }
  if (value > 1024) {
    return (value / 1024).toFixed(1) + ' TiB'
  }
  return Math.round(value).toLocaleString() + ' GiB'
}

</script>

<style scoped>
.cpumem-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 33px;
  margin-left: 20px;
  margin-right: 5px;
}

.cpumem-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  width: 150px;
  height: 190px;
}

.bar-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;

}

.bar-label {
  color: #d3d3d3;
  font-weight: bold;
  margin-bottom: 13px;
}

.bar-wrapper {
  position: relative;
  width: 23px;
  height: 157px;
  background: #1e252f;
  border: 1px solid #444;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  background: repeating-linear-gradient(135deg, #efefef40, #efefef40 4px, #cdcdcd0d 0, #cdcdcd0d 8px);
}

.bar {
  width: 100%;
  transition: height 0.2s ease-in-out;
}

.bar.avg {
  background-color: #F18127;
  z-index: 1;
  border-top: .5px solid #b3682b;
}

.bar.peak {
  background-color: #BD9584;
  position: absolute;
  bottom: 0;
  border-top: .5px solid #4d3b34;
}

.bar-values {
  color: #ccc;
  font-size: 12px;
  margin-top: 8px;
  text-align: center;
}

.legend {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: 8px;
  padding-left: 2px;
  margin-left: 12px;
  padding-top: 7px;
  border-top: 1px solid #2f353d;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 3px;
}

.legend-color {
  width: 10px;
  height: 10px;
  border-radius: 2px;
}

/* Colors matching the bars */
.legend-color.avg-bar {
  background-color: rgb(241, 129, 39);
}

.legend-color.peak-bar {
  background-color: #BD9584;
}

.legend-color.buff-bar {
  background-color: #9DBAEF;
}

.legend-label {
  font-size: 12px;
  color: #b8b8b8;
  padding-left: 5px;
}

</style>